import React from 'react';
import { useUser } from '@auth0/nextjs-auth0';

const Login = () => {
  const { error, isLoading } = useUser();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  return <a href="/api/auth/login">Login</a>;
};

export default Login;
